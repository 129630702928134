<template>
    <page
        id="reports"
        :title="$root.translate('Report:::Reports', {}, 2)"
        :status="page_status"
        :waiting="waiting"
        :is-root="true"
        @statused="statused"
    >
        <h2>{{ translate("Watchman Computers") }}</h2>
        <data-table
            id="reports"
            :loading="loading"
            :columns="visible_columns"
            :rows="filtered_rows"
            :search="state.search"
            :total="state.total"
            :found="state.found"
            :current-page="state.page"
            :total-pages="nb_pages"
            :searchable="false"
            :can-add="false"
            :clickable="false"
        >
            <template
                v-slot:summary="{ row }"
            >
                <h6>{{ row.name }}</h6>
            </template>
            <template
                v-slot:tbody="{ row, field }"
            >
                <span v-if="field === 'name'">{{ row.name }}</span>
                <a
                    v-else
                    href="#"
                    :class="{ 'text-bold': row[field].added || row[field].removed }"
                    @click.prevent.stop="display_details(row, field)"
                >
                    {{ row[field].count | nibnut.number("0,0") }}
                    <span
                        v-if="row[field].added || row[field].removed"
                        v-html="changes_for_field(row[field])"
                    ></span>
                </a>
            </template>
            <template v-slot:tfoot>
                <tfoot>
                    <tr class="text-bold">
                        <td
                            v-for="(column, column_id) in visible_columns"
                            :key="column_id"
                            :class="{ 'text-right': (column_id === 'name') }"
                        >
                            <div v-if="column_id === 'name'" class="text-uppercase">{{ translate("total") }}</div>
                            <div v-else>
                                {{ column_total(column_id).count | nibnut.number("0,0") }}
                                <span
                                    v-if="column_total(column_id).added || column_total(column_id).removed"
                                    v-html="changes_for_field(column_total(column_id))"
                                ></span>
                            </div>
                        </td>
                    </tr>
                </tfoot>
            </template>
        </data-table>

        <modal-dialog
            id="report-detail-dialog"
            :show.sync="displaying"
        >
            <template v-slot:title>
                <span class="h5">{{ details.title }}</span>
            </template>

            <table class="table table-striped">
                <tbody>
                    <tr
                        v-for="computer in computers"
                        :key="computer.id"
                        :class="{ 'text-error': (!!computer.logs && !!computer.logs.find(log => log.match(/removed/i))), 'text-success': (!!computer.logs && !!computer.logs.find(log => log.match(/added/i))) }"
                    >
                        <td>
                            {{ computer.name }}
                            <div v-if="!!computer.logs && !!computer.logs.length" class="text-small">({{ computer.logs.join(", ") }})</div>
                        </td>
                        <td style="width: 100px;">
                            {{ translate("1 day:::{n} days", { n: computer.active_days }, computer.active_days) }}
                        </td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <td colspan="2">
                            <h6 class="text-uppercase">{{ translate("total") }}:&nbsp;{{ details.count | nibnut.number("0,0") }}</h6>
                        </td>
                    </tr>
                </tfoot>
            </table>
        </modal-dialog>
    </page>
</template>

<script type="text/javascript">
import orderBy from "lodash/orderBy"

import { is_page, is_data_table_source } from "@/nibnut/mixins"

import {
    ModalDialog
} from "@/nibnut/components"

export default {
    mixins: [is_page, is_data_table_source],
    components: {
        ModalDialog
    },
    mounted () {
        this.reset() // reset state && reload data
    },
    watch: {
        profile_id: "reset",
        $route: "reset"
    },
    methods: {
        reset () {
            if(this.loading || !this.profile_id) return
            this.loading = true
            this.$store.dispatch(
                "RECORDS_ACTION",
                {
                    entity: "report",
                    action: "statistics",
                    passthru: true
                }
            ).then(data => {
                this.raw_data = data
                if(!!this.raw_data && !!this.raw_data.watchman && !!this.raw_data.watchman.dates) {
                    Object.keys(this.raw_data.watchman.dates).forEach(column => {
                        if(this.columns[column]) this.columns[column].label = this.raw_data.watchman.dates[column]
                    })
                }
                this.refresh()
            }).catch(error => {
                this.$error(error.message)
            }).then(() => {
                this.loading = false
            })
        },
        changes_for_field (statistics) {
            const tokens = []
            if(statistics.added) tokens.push(`+${statistics.added}`)
            if(statistics.removed) tokens.push(`-${statistics.removed}`)
            return `&nbsp;(${tokens.join(", ")})`
        },
        column_total (column_id) {
            const rows = this.rows
            const total = {
                count: 0,
                added: 0,
                removed: 0
            }
            rows.forEach(row => {
                total.count += row[column_id].count
                total.added += row[column_id].added
                total.removed += row[column_id].removed
            })
            return total
        },
        display_details (row, field) {
            this.details = {
                title: `${row.name} - ${this.columns[field].label}`,
                ...row[field]
            }
            this.displaying = true
        }
    },
    computed: {
        rows () {
            if(!!this.raw_data && !!this.raw_data.watchman && !!this.raw_data.watchman.rows) return this.raw_data.watchman.rows
            return []
        },
        computers () {
            if(!this.details || !this.details.computers) return []
            return orderBy(Object.values(this.details.computers), function (computer) { return (!!computer.logs && !!computer.logs.length) ? computer.active_days - 32 : computer.active_days }, "asc")
        }
    },
    data () {
        return {
            loading: false,
            columns: {
                name: { label: "Client", sort: false },
                month1: { label: "Month 1", sort: false },
                month2: { label: "Month 2", sort: false },
                month3: { label: "Month 3", sort: false },
                month4: { label: "Month 4", sort: false },
                month5: { label: "Month 5", sort: false },
                month6: { label: "Month 6", sort: false }
            },
            default_state: {
                per_page: 0,
                page: 1,
                sort_by: "name",
                sort_dir: "asc",
                filter_on: null,
                filter: null,
                archived: false,
                search: "",
                total: 0,
                found: 0
            },
            raw_data: {},
            displaying: false,
            details: {}
        }
    }
}
</script>
